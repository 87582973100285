import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./Store/store";
import { ConfigProvider, theme } from "antd";
import customizeRenderEmpty from "./Components/Common/Common/RenderEmpty";
import "./index.css";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <ConfigProvider
          renderEmpty={customizeRenderEmpty}
          theme={{
            token: {
              colorPrimary: "#005B60",
              colorSecondary: "#FF601B",
              colorPrimaryActive: "#005B60",
              colorPrimaryBorder: "#005B60",
              colorPrimaryHover: "#005B60",
              colorText: "#005B60",
            },
          }}>
          <App />
        </ConfigProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);
