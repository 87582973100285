import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { baseUrl } from "../Utils/api";
import axios from "axios";

const initialState = {
  userData: {},
  loading: false,
};
const token = localStorage.getItem("auth-token");

export const getPASUsers = createAsyncThunk(
  "user/getPASUsers",
  async (pagination) => {
    let str = "";
    if (pagination.page) {
      str = `?page=${pagination.page - 1}`;
    }
    if (pagination.size) {
      str = str ? `${str}&size=${pagination.size}` : `?size=${pagination.size}`;
    }
    return await fetch(`${baseUrl}/user/profiles/Sales_Distribution${str}`, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => res.result)
      .catch((e) => console.log(e));
  }
);

export const addUser = createAsyncThunk(
  "addUser",
  async ({ data, api, pagination }, { dispatch }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${baseUrl}/user`,
        headers: {
          Authorization: `Bearer ${token}`,
          "content-Type": "application/json",
        },
        data: JSON.stringify(data),
      });
      if (response?.data?.api?.responseCode === 2010) {
        api.success({
          message: "New User Created!",
          style: {
            width: 300,
          },
        });
        dispatch(getPASUsers(pagination));
      } else if (response?.data?.api?.responseCode === 3530) {
        api.warning({
          message: `User Already Exists!`,
          style: {
            width: 300,
          },
        });
      }

      return response;
    } catch (error) {
      api.error({
        message: "Something went wrong!",
        style: {
          width: 300,
        },
      });
      console.log(error);
    }
  }
);

export const deleteUser = createAsyncThunk(
  "deleteUser",
  async ({ id, api, pagination }, { dispatch }) => {
    try {
      const response = await axios({
        method: "DELETE",
        url: `${baseUrl}/user/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "content-Type": "application/json",
        },
      });
      console.log(response);
      if (response?.data?.api?.responseCode === 2050) {
        api.success({
          message: "User Deleted!",
          style: {
            width: 300,
          },
        });
        dispatch(getPASUsers(pagination));
      }
      // else if (response?.data?.api?.responseCode === 3530) {
      //   api.warning({
      //     message: `User Already Exists!`,
      //     style: {
      //       width: 300,
      //     },
      //   });
      // }

      return response;
    } catch (error) {
      api.error({
        message: "Something went wrong!",
        style: {
          width: 300,
        },
      });
      console.log(error);
    }
  }
);

export const activateUser = createAsyncThunk(
  "activateUser",
  async ({ id, api, pagination }, { dispatch }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${baseUrl}/user/${id}/activate`,
        headers: {
          Authorization: `Bearer ${token}`,
          "content-Type": "application/json",
        },
      });
      if (response?.data?.api?.responseCode === 2030) {
        api.success({
          message: "User Activated!",
          description:
            " An activation email has been sent on User's registered email",
          style: {
            width: 300,
          },
        });
        dispatch(getPASUsers(pagination));
      }
      // else if (response?.data?.api?.responseCode === 3530) {
      //   api.warning({
      //     message: `User Already Exists!`,
      //     style: {
      //       width: 300,
      //     },
      //   });
      // }

      return response;
    } catch (error) {
      api.error({
        message: "Something went wrong!",
        style: {
          width: 300,
        },
      });
      console.log(error);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "resetPassword",
  async (newPassword) => {
    const data = {
      new_password: 1995,
      token: token,
    };
    try {
      const response = await axios({
        method: "POST",
        url: `${baseUrl}/user/account/reset-password`,
        headers: {
          Authorization: `Bearer ${token}`,
          "content-Type": "application/json",
        },
        data: JSON.stringify(data),
      });
      // if (response?.data?.api?.responseCode === 2030) {
      //   api.success({
      //     message: "User Activated!",
      //     description:
      //       " An activation email has been sent on User's registered email",
      //     style: {
      //       width: 300,
      //     },
      //   });
      // }
      // else if (response?.data?.api?.responseCode === 3530) {
      //   api.warning({
      //     message: `User Already Exists!`,
      //     style: {
      //       width: 300,
      //     },
      //   });
      // }

      return response;
    } catch (error) {
      // api.error({
      //   message: "Something went wrong!",
      //   style: {
      //     width: 300,
      //   },
      // });
      console.log(error);
    }
  }
);

export const generateToken = createAsyncThunk(
  "generateToken",
  async ({ email, api }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${baseUrl}/user/account/reset-password/init-token/${email}/Sales_Distribution`,
        headers: {
          Authorization: `Bearer ${token}`,
          "content-Type": "application/json",
        },
      });
      const { data } = response;
      if (data?.api?.responseCode === 3050) {
        api.success({
          message: "Password reset link sent!",
          description: "you can reset your password through your mail.",
          style: {
            width: 300,
          },
        });
      }
      if (data?.api?.responseCode === 5210) {
        api.error({
          message: "Token already sent!",
          style: {
            width: 300,
          },
        });
      }
      return response;
    } catch (error) {
      api.error({
        message: error?.response?.data?.message,
        style: {
          width: 300,
        },
      });
    }
  }
);

const authSlice = createSlice({
  name: "authSlice",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getPASUsers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPASUsers.fulfilled, (state, action) => {
      state.loading = false;
      state.userData = action?.payload;
    });
    builder.addCase(getPASUsers.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(addUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addUser.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(addUser.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(deleteUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteUser.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(deleteUser.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(activateUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(activateUser.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(activateUser.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(resetPassword.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(generateToken.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(generateToken.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(generateToken.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export default authSlice.reducer;
