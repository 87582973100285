import { Navigate } from "react-router-dom";
// import Main from '../components/layout/Main';
export const PrivateRoute = ({ children }) => {
  return localStorage.getItem("auth-token") ? (
    children
  ) : (
    <Navigate to="/login" replace />
  );
  // return children;
};
