import { SmileOutlined } from "@ant-design/icons";

const customizeRenderEmpty = () => (
  <div style={{ textAlign: "center" }}>
    <SmileOutlined style={{ fontSize: 20 }} />
    <p>Data Not Found</p>
  </div>
);

export default customizeRenderEmpty;
