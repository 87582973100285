import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { baseUrl } from "../Utils/api";
import axios from "axios";

const initialState = {
  formData: {},
  loading: false,
  summaryData: {},
  steps: [],
  quotationData: {},
  quotationFormLoading:false,
  calculatePremiumLoading:false
};
const token = localStorage.getItem("auth-token");

export const fetchQoutationForm = createAsyncThunk(
  "fetchQoutationForm",
  async () => {
    try {
      const response = await axios({
        method: "GET",
        url: `${baseUrl}/quotation/form`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  }
);

export const calculateQuotationData = createAsyncThunk(
  "calculateQuotationData",
  async (quotationData) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${baseUrl}/quotation/calculate`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(quotationData),
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  }
);

export const saveQuote = createAsyncThunk(
  "saveQuote",
  async (quotationData) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${baseUrl}/quotation`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(quotationData),
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  }
);

export const ammendQuote = createAsyncThunk(
  "saveQuote",
  async (quotationData) => {
    const data = { ...quotationData };
    delete data.id;
    delete data.created_at;
    delete data.updated_at;
    delete data.quotation_status;
    data.discount = 0;
    try {
      const response = await axios({
        method: "PUT",
        url: `${baseUrl}/quotation/${quotationData?.id}/ammend`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(data),
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  }
);

const quotationSlice = createSlice({
  name: "quotationSlice",
  initialState: initialState,
  reducers: {
    editQuote: (state, payload) => {
      const current = payload.payload.current;
      const stepsArr = [];
      if (current.indexOf("customer") !== -1) stepsArr.push(1);
      if (current.indexOf("location") !== -1) stepsArr.push(2);
      if (current.indexOf("benefits") !== -1) stepsArr.push(3);
      if (current.indexOf("members") !== -1) stepsArr.push(4);
      if (current.indexOf("copay") !== -1) stepsArr.push(5);
      state.steps = stepsArr;
      state.quotationData = payload.payload.quotationRequest;
      console.log(state.quotationData)
    },
    removeEditQuote: (state) => {
      state.quotationData = {};
      state.steps = [];
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchQoutationForm.pending, (state) => {
      state.loading = true;
      state.fetchQoutationForm=true
    });
    builder.addCase(fetchQoutationForm.fulfilled, (state, action) => {
      state.loading = false;
      state.fetchQoutationForm=false
      state.formData = action?.payload?.data;
    });
    builder.addCase(fetchQoutationForm.rejected, (state, action) => {
      state.loading = false;
      state.fetchQoutationForm=false
    });
    builder.addCase(calculateQuotationData.pending, (state) => {
      state.loading = true;
      state.calculatePremiumLoading=true
    });
    builder.addCase(calculateQuotationData.fulfilled, (state, action) => {
      state.loading = false;
      state.calculatePremiumLoading=false
      state.summaryData = action?.payload?.data;
    });
    builder.addCase(calculateQuotationData.rejected, (state, action) => {
      state.calculatePremiumLoading=false
    });

    builder.addCase(saveQuote.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(saveQuote.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(saveQuote.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const { editQuote, removeEditQuote } = quotationSlice.actions;

export default quotationSlice.reducer;
